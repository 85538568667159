import { RouteRecordRaw } from "vue-router";
import { breakpointsBootstrapV5 } from "@vueuse/core";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "default-layout" */ "@/layouts/Default.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "default-layout" */ "@/views/Home.vue"),
      },
      {
        path: "faq",
        name: "FAQ",
        component: () =>
          import(/* webpackChunkName: "default-layout" */ "@/views/FAQ.vue"),
      },
      {
        path: "contact",
        name: "Contact",
        component: () =>
          import(
            /* webpackChunkName: "default-layout" */ "@/views/Contact.vue"
          ),
      },
      {
        path: "page",
        name: "Page",
        component: () =>
          import(/* webpackChunkName: "default-layout" */ "@/views/Page.vue"),
      },
    ],
  },
  {
    path: "/auth",
    beforeEnter: () => {
      window.location.href = "https://new.wallet.ir";
    },
    component: {
      render() {
        return null;
      },
    },
    children: [
      {
        path: "",
        name: "Auth",

        beforeEnter: () => {
          window.location.href = "https://new.wallet.ir";
        },
        component: () =>
          import(/* webpackChunkName: "auth" */ "@/views/Auth.vue"),
        meta: {
          title: "ورود / ثبت نام",
        },
      },
      {
        path: "verify",
        name: "Verify",

        beforeEnter: () => {
          window.location.href = "https://new.wallet.ir";
        },
        component: () =>
          import(/* webpackChunkName: "auth" */ "@/views/Verify.vue"),
        meta: {
          title: "کد تایید را وارد کنید",
        },
      },
    ],
  },
  // {
  //   path: "/auth",
  //   component: () =>
  //     import(/* webpackChunkName: "auth" */ "@/layouts/Auth.vue"),
  //   children: [
  //     {
  //       path: "",
  //       name: "Auth",
  //       component: () =>
  //         import(/* webpackChunkName: "auth" */ "@/views/Auth.vue"),
  //       meta: {
  //         title: "ورود / ثبت نام",
  //       },
  //     },
  //     {
  //       path: "verify",
  //       name: "Verify",
  //       component: () =>
  //         import(/* webpackChunkName: "auth" */ "@/views/Verify.vue"),
  //       meta: {
  //         title: "کد تایید را وارد کنید",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/account",
    component: () =>
      document.body.clientWidth < breakpointsBootstrapV5.lg
        ? import(/* webpackChunkName: "account" */ "@/layouts/Account.vue")
        : import(
            /* webpackChunkName: "default-layout" */ "@/layouts/Default.vue"
          ),
    children: [
      {
        path: "",
        name: "Account",
        component: () =>
          import(/* webpackChunkName: "account" */ "@/views/Account/Index.vue"),
        meta: {
          title: "مدیریت حساب ها",
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Profile.vue"
          ),
      },
      {
        path: "support",
        name: "Support",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Support.vue"
          ),
        meta: {
          title: "پشتیبانی",
        },
      },
      {
        path: "order",
        name: "Order",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Order/Index.vue"
          ),
      },
      {
        path: "order/sell/:id",
        name: "Sell",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Order/Sell/Index.vue"
          ),
        meta: {
          title: "سفارش فروش",
        },
      },
      {
        path: "order/sell/status/:id",
        name: "SellStatus",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Order/Sell/Status.vue"
          ),
        meta: {
          title: "فروش تتر به ما",
        },
      },
      {
        path: "order/sell/invoice/:id",
        name: "SellInvoice",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Order/Sell/Invoice.vue"
          ),
        meta: {
          title: "فروش تتر به ما",
        },
      },
      {
        path: "order/buy/:id",
        name: "Buy",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Order/Buy/Index.vue"
          ),
        meta: {
          title: "سفارش خرید",
        },
      },
      {
        path: "order/buy/status/:id",
        name: "BuyStatus",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Order/Buy/Status.vue"
          ),
        meta: {
          title: "سفارش خرید",
        },
      },
      {
        path: "order/buy/invoice/:id",
        name: "BuyInvoice",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Order/Buy/Invoice.vue"
          ),
        meta: {
          title: "خرید تتر از ما",
        },
      },
      {
        path: "transactions",
        name: "Transactions",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/Account/Transactions.vue"
          ),
        meta: {
          title: "تاریخچه تراکنش ها",
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)*",
    beforeEnter: (to, from, next) => {
      next("/404");
    },
    component: () =>
      import(/* webpackChunkName: "default-layout" */ "@/views/404.vue"),
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "default-layout" */ "@/views/404.vue"),
  },
];

export default routes;
